<template>
    <div class="announce">
        <div class="announce-wrap" ref="gwn">
            <h2>{{detailInfo.title}}</h2>
            <div class="announce-time">{{detailInfo.created_at}}</div>
            <div class="announce-html ql-editor" v-html="detailInfo.content"></div>
        </div>
        <loadings :loading='searchLoading' />
    </div>
</template>

<script>
    import gwm from 'gwm'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import Loadings from '@/components/loading'
    export default {
        name:'announce',
        components: {
            loadings:Loadings,
        },
        data() {
            return {
                detailInfo: {},
                searchLoading:false,
            }
        },
        created () {
            this.getDetail()
            this.getEmployee()
        },
        methods: {
            getDetail() {
                this.searchLoading = true
                this.$api.oa_announce_detail({announcement_id:this.$route.query.announcement_id})
                .then(res=>{
                    this.detailInfo = res.data
                    this.searchLoading = false
                    this.toSeen()
                })
            },
            toSeen() {
                this.$api.oa_announce_seen({announcement_id:this.$route.query.announcement_id})
            },
            getEmployee(){
                this.$api.employee_index().then(res => {
                    localStorage.setItem('employee_watermark',res.data.employee.employee_watermark)
                    this.$nextTick(()=>{
                        console.log(this.$refs.gwn,'this.$refs.gwn')
                        gwm.creation({
                            container:this.$refs.gwn,
                            txt:localStorage.getItem('employee_watermark'),
                            mode: 'svg',
                            watch: false,
                            fontSize: 11,
                            height:80,
                            x: 20,
                            y: 30,
                            color: '#bbbfca',
                            font: 'sans-serif',
                            alpha: 0.2,
                            angle: -15
                        })
                    })
                })
            },
        },
    }
</script>

<style lang="less" scoped>
    .announce{
        min-height: 100vh;
        background: #fff;
        &-wrap{
            width: 95%;
            margin: 0 auto;
            padding-top: 20px;
        }
        h2{
            font-size: 16px;
        }
        &-time{
            font-size: 13px;
            color: #969799;
            margin-top: 5px;
            margin-bottom: 20px;
        }
    }
</style>

<style lang="less">
    .announce-html{
        img{
            max-width: 100%;
        }
    }
</style>