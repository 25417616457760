import { render, staticRenderFns } from "./announce.vue?vue&type=template&id=73c38ab2&scoped=true&"
import script from "./announce.vue?vue&type=script&lang=js&"
export * from "./announce.vue?vue&type=script&lang=js&"
import style0 from "./announce.vue?vue&type=style&index=0&id=73c38ab2&lang=less&scoped=true&"
import style1 from "./announce.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73c38ab2",
  null
  
)

export default component.exports